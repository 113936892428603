<template>
    <v-dialog v-model="showingModal" width="40%">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Contacto: {{contact.name }}
            </v-card-title>
            
            <v-card-text>
                <v-expansion-panels
                flat
                multiple
                v-model="expansion_panel"
                >
                    <v-expansion-panel v-for="(phone, idx) in contact.phones" class="mb-2" :key="idx">
                        <v-expansion-panel-header>Teléfono: {{ phone.phone }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div>
                                <v-card-subtitle 
                                v-if="phone.correct === false"
                                class="pt-8 no-datos-span"
                                >
                                    Contacto dado de baja
                                </v-card-subtitle>
                                <v-card-subtitle 
                                v-if="phone.correct === true"
                                class="pt-8 no-datos-span"
                                >
                                    Contacto confirmado
                                </v-card-subtitle>
                                <v-card-subtitle 
                                v-if="!phone.link1 && !phone.link2 && !phone.email && !phone.dato1 && !phone.dato2 && !phone.dato3 && !phone.dato4 && !phone.dato5 && !phone.dato6 && !phone.dato7 && !phone.dato8 && !phone.dato9 && !phone.dato10"
                                class="pt-8 no-datos-span"
                                >
                                    No se ha ingresado ningún dato extra.
                                </v-card-subtitle>
                                <v-list>
                                    <div>
                                        <v-list-item-content v-if="phone.link1">
                                            <v-list-item-title>Link 1:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.link1}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.link2">
                                            <v-list-item-title>Link 2:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.link2}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.email">
                                            <v-list-item-title>Email:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.email}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato1">
                                            <v-list-item-title>Dato 1:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato1}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato2">
                                            <v-list-item-title>Dato 2:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato2}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato3">
                                            <v-list-item-title>Dato 3:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato3}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato4">
                                            <v-list-item-title>Dato 4:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato4}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato5">
                                            <v-list-item-title>Dato 5:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato5}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato6">
                                            <v-list-item-title>Dato 6:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato6}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato7">
                                            <v-list-item-title>Dato 7:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato7}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato8">
                                            <v-list-item-title>Dato 8:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato8}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato9">
                                            <v-list-item-title>Dato 9:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato9}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="phone.dato10">
                                            <v-list-item-title>Dato 10:</v-list-item-title>
                                            <v-list-item-subtitle>{{phone.dato10}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </div>
                                </v-list>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data:() => {
        return {
            showingModal: false,
            contact: {},
            expansion_panel: []
        }
    },
    methods: {
        open(item) {
            this.contact = item
            this.showingModal = true
        },
        close() {
            this.contact = {}
            this.showingModal = false
        }
    }
}
</script>

<style>
.no-datos-span {
    font-size: 16px !important;
    font-weight: 600;
    padding-left: 5px !important;
}
</style>
